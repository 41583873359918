body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  overflow-x: hidden;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

.heroSectionText {
  margin-top: 150px !important;
  align-self: center;
  animation: appearAni 0.5s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.aboutSectionText {
  margin-top: 48px !important;
  /* align-self: center; */
  animation: appearAni 0.5s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.gutterFix {
  --bs-gutter-x: 0 !important;
}

@media only screen and (min-device-width: 50px) and (max-device-width: 575px) {
  .caseStudyHeight {
    margin-top: 0px;
  }

  .caseStudyImageHeight {
    margin-top: 75px !important;
  }
}

@media only screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .caseStudyHeight {
    margin-top: 0px;
  }
  .caseStudyImageHeight {
    margin-top: 75px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .caseStudyHeight {
    margin-top: 0px !important;
  }
  .caseStudyImageHeight {
    margin-top: 75px !important;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  .caseStudyHeight {
    margin-top: 75px !important;
  }
  .caseStudyImageHeight {
    margin-top: 75px !important;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1399px) {
  .caseStudyHeight {
    margin-top: 75px !important;
  }
  .caseStudyImageHeight {
    margin-top: 75px !important;
  }
}

@media only screen and (min-device-width: 1400px) {
  .caseStudyHeight {
    margin-top: 75px !important;
  }
  .caseStudyImageHeight {
    margin-top: 75px !important;
  }
}
