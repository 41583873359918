/* 
Commenting fonts:
Section Heading: ANSI Shadow
Deep Section: Big money ne
Element Group Heading: Calvin S
*/

/* 

██████╗  ██████╗  ██████╗ ████████╗    ███████╗███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
██╔══██╗██╔═══██╗██╔═══██╗╚══██╔══╝    ██╔════╝██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
██████╔╝██║   ██║██║   ██║   ██║       ███████╗█████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
██╔══██╗██║   ██║██║   ██║   ██║       ╚════██║██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
██║  ██║╚██████╔╝╚██████╔╝   ██║       ███████║███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
╚═╝  ╚═╝ ╚═════╝  ╚═════╝    ╚═╝       ╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
                                                                                              
ROOT SECTION

*/

/* 
╦╔═╔═╗╦ ╦  ╔═╗╔═╗╦  ╔═╗╦═╗╔═╗
╠╩╗║╣ ╚╦╝  ║  ║ ║║  ║ ║╠╦╝╚═╗
╩ ╩╚═╝ ╩   ╚═╝╚═╝╩═╝╚═╝╩╚═╚═╝
KEY COLORS
*/

:root {
  --bg-color: #383838;
  --primary-color: #eb3f43;
  --secondary-color: #ffa910;
  --tertiary-color: #ffffff;
  background-color: var(--bg-color) !important;
}

.failText {
  color: rgb(255, 152, 152) !important;
}
/*  
╔═╗╔═╗╦═╗╔═╗╦  ╦  ╔╗ ╔═╗╦═╗  ╔═╗╔╦╗╦ ╦╦  ╦╔╗╔╔═╗
╚═╗║  ╠╦╝║ ║║  ║  ╠╩╗╠═╣╠╦╝  ╚═╗ ║ ╚╦╝║  ║║║║║ ╦
╚═╝╚═╝╩╚═╚═╝╩═╝╩═╝╚═╝╩ ╩╩╚═  ╚═╝ ╩  ╩ ╩═╝╩╝╚╝╚═╝
SCROLLBAR STYLING
*/

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--tertiary-color);
}

/* 
╔═╗╔═╗╔╗╔╔╦╗╔═╗   ┬   ╦ ╦╔═╗╔═╗╔╦╗╦╔╗╔╔═╗╔═╗
╠╣ ║ ║║║║ ║ ╚═╗  ┌┼─  ╠═╣║╣ ╠═╣ ║║║║║║║ ╦╚═╗
╚  ╚═╝╝╚╝ ╩ ╚═╝  └┘   ╩ ╩╚═╝╩ ╩═╩╝╩╝╚╝╚═╝╚═╝
FONTS & HEADINGS 
*/

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  font-family: "Open Sans";
}

h1 {
  font-size: 48px !important;
  margin-left: -3.5px !important;
}

h2 {
  font-size: 24px !important;
}

h3 {
  font-size: 20px !important;
  letter-spacing: 0.4px !important;
  line-height: 28px !important;
}

h4 {
  font-size: 11px !important;
  letter-spacing: 1px !important;
}

.spanH4 {
  font-size: 11px !important;
  letter-spacing: 1px !important;
}

h5 {
  font-size: 16px !important;
  line-height: 1.5 !important;
}

p {
  font-size: 16px !important;
  line-height: 1.5 !important;
}

.lightText {
  font-weight: 300 !important;
}

.normalText {
  font-weight: 400 !important;
}

.boldText {
  font-weight: 600 !important;
}

.extraBoldText {
  font-weight: 800 !important;
}

/* 
╔═╗╔═╗╔╗╔╔╦╗  ╔═╗╔═╗╦  ╔═╗╦═╗╔═╗   ┬   ╦ ╦╔═╗╦  ╦╔═╗╦═╗╔═╗
╠╣ ║ ║║║║ ║   ║  ║ ║║  ║ ║╠╦╝╚═╗  ┌┼─  ╠═╣║ ║╚╗╔╝║╣ ╠╦╝╚═╗
╚  ╚═╝╝╚╝ ╩   ╚═╝╚═╝╩═╝╚═╝╩╚═╚═╝  └┘   ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═╚═╝
FONT COLORS & HOVERS
*/

.bgText {
  color: var(--bg-color) !important;
}

.bgHover:hover {
  color: var(--bg-color) !important;
}

.tertiaryText {
  color: var(--tertiary-color) !important;
}

.tertiaryHover:hover {
  color: var(--tertiary-color) !important;
}

.primaryText {
  color: var(--primary-color) !important;
}

.primaryHover:hover {
  color: var(--primary-color) !important;
}

.secondaryText {
  color: var(--secondary-color) !important;
}

.secondaryHover:hover {
  color: var(--secondary-color) !important;
}

/* 
╔╗ ╔═╗╔═╗╦╔═╔═╗╦═╗╔═╗╦ ╦╔╗╔╔╦╗  ╔═╗╔═╗╦  ╔═╗╦═╗╔═╗
╠╩╗╠═╣║  ╠╩╗║ ╦╠╦╝║ ║║ ║║║║ ║║  ║  ║ ║║  ║ ║╠╦╝╚═╗
╚═╝╩ ╩╚═╝╩ ╩╚═╝╩╚═╚═╝╚═╝╝╚╝═╩╝  ╚═╝╚═╝╩═╝╚═╝╩╚═╚═╝
BACKGROUND COLORS
*/

.secondaryBG {
  background-color: var(--secondary-color) !important;
}

.primaryBG {
  background-color: var(--primary-color) !important;
}

.baseBG {
  background-color: var(--bg-color) !important;
}

.tertiaryBG {
  background-color: var(--tertiary-color) !important;
}

/* 
╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔╔═╗
╠╩╗║ ║ ║  ║ ║ ║║║║╚═╗
╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝╚═╝
BUTTONS
*/

/* 
  /$$$$$$  /$$$$$$$  /$$$$$$$$ /$$$$$$$$ /$$   /$$
 /$$__  $$| $$__  $$| $$_____/| $$_____/| $$$ | $$
| $$  \__/| $$  \ $$| $$      | $$      | $$$$| $$
| $$ /$$$$| $$$$$$$/| $$$$$   | $$$$$   | $$ $$ $$
| $$|_  $$| $$__  $$| $$__/   | $$__/   | $$  $$$$
| $$  \ $$| $$  \ $$| $$      | $$      | $$\  $$$
|  $$$$$$/| $$  | $$| $$$$$$$$| $$$$$$$$| $$ \  $$
 \______/ |__/  |__/|________/|________/|__/  \__/
                                                  
 */

.greenButton {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.greenButton:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.greenButton:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

/* 
╔═╗╦═╗╔═╗╔═╗╔╗╔  ╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔
║ ╦╠╦╝║╣ ║╣ ║║║  ╠╩╗║ ║ ║  ║ ║ ║║║║
╚═╝╩╚═╚═╝╚═╝╝╚╝  ╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝
╦ ╦╦ ╦╦╔╦╗╔═╗  ╦ ╦╔═╗╦  ╦╔═╗╦═╗
║║║╠═╣║ ║ ║╣   ╠═╣║ ║╚╗╔╝║╣ ╠╦╝
╚╩╝╩ ╩╩ ╩ ╚═╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═
╔╗ ╔═╗  ╔╗ ╔═╗╦═╗╔╦╗╔═╗╦═╗     
╠╩╗║ ╦  ╠╩╗║ ║╠╦╝ ║║║╣ ╠╦╝     
╚═╝╚═╝  ╚═╝╚═╝╩╚══╩╝╚═╝╩╚═     
*/

.greenButtonBGBorder {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.greenButtonBGBorder:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--bg-color) !important;
}

.greenButtonBGBorder:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

/* 
╔═╗╦═╗╔═╗╔═╗╔╗╔  ╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔
║ ╦╠╦╝║╣ ║╣ ║║║  ╠╩╗║ ║ ║  ║ ║ ║║║║
╚═╝╩╚═╚═╝╚═╝╝╚╝  ╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝
╔═╗╦═╗╔═╗╔╗╔╔═╗╔═╗  ╦ ╦╔═╗╦  ╦╔═╗╦═╗
║ ║╠╦╝╠═╣║║║║ ╦║╣   ╠═╣║ ║╚╗╔╝║╣ ╠╦╝
╚═╝╩╚═╩ ╩╝╚╝╚═╝╚═╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═
*/

.greenButtonOrangeHover {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.greenButtonOrangeHover:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.greenButtonOrangeHover:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

.greenButtonDummy {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

/* 
  /$$$$$$  /$$$$$$$   /$$$$$$  /$$   /$$  /$$$$$$  /$$$$$$$$
 /$$__  $$| $$__  $$ /$$__  $$| $$$ | $$ /$$__  $$| $$_____/
| $$  \ $$| $$  \ $$| $$  \ $$| $$$$| $$| $$  \__/| $$      
| $$  | $$| $$$$$$$/| $$$$$$$$| $$ $$ $$| $$ /$$$$| $$$$$   
| $$  | $$| $$__  $$| $$__  $$| $$  $$$$| $$|_  $$| $$__/   
| $$  | $$| $$  \ $$| $$  | $$| $$\  $$$| $$  \ $$| $$      
|  $$$$$$/| $$  | $$| $$  | $$| $$ \  $$|  $$$$$$/| $$$$$$$$
 \______/ |__/  |__/|__/  |__/|__/  \__/ \______/ |________/
                                                            
                                                            
                                                            
*/

/* 
GREEN BUTTON
ORANGE HOVER
*/

.orangeButtonGreenHover {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.orangeButtonGreenHover:hover {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.orangeButtonGreenHover:focus {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

/* 
╔═╗╦═╗╔═╗╔╗╔╔═╗╔═╗  ╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔
║ ║╠╦╝╠═╣║║║║ ╦║╣   ╠╩╗║ ║ ║  ║ ║ ║║║║
╚═╝╩╚═╩ ╩╝╚╝╚═╝╚═╝  ╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝
╔═╗╦═╗╔═╗╔═╗╔╗╔  ╦ ╦╔═╗╦  ╦╔═╗╦═╗     
║ ╦╠╦╝║╣ ║╣ ║║║  ╠═╣║ ║╚╗╔╝║╣ ╠╦╝     
╚═╝╩╚═╚═╝╚═╝╝╚╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═          
*/
/* .orangeButtonGreenHover {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.orangeButtonGreenHover:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.orangeButtonGreenHover:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
  box-shadow: 0 0 0 2px var(--secondary-color) !important;
} */

/* 
╔═╗╦═╗╔═╗╔╗╔╔═╗╔═╗  ╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔
║ ║╠╦╝╠═╣║║║║ ╦║╣   ╠╩╗║ ║ ║  ║ ║ ║║║║
╚═╝╩╚═╩ ╩╝╚╝╚═╝╚═╝  ╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝
╦ ╦╦ ╦╦╔╦╗╔═╗  ╦ ╦╔═╗╦  ╦╔═╗╦═╗       
║║║╠═╣║ ║ ║╣   ╠═╣║ ║╚╗╔╝║╣ ╠╦╝       
╚╩╝╩ ╩╩ ╩ ╚═╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═       
*/
.orangeButtonWhiteHover {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.orangeButtonWhiteHover:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.orangeButtonWhiteHover:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important;
  box-shadow: 0 0 0 2px var(--secondary-color) !important;
}

/* 
╔═╗╦═╗╔═╗╔╗╔╔═╗╔═╗  ╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔
║ ║╠╦╝╠═╣║║║║ ╦║╣   ╠╩╗║ ║ ║  ║ ║ ║║║║
╚═╝╩╚═╩ ╩╝╚╝╚═╝╚═╝  ╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝
╦ ╦╦ ╦╦╔╦╗╔═╗  ╦ ╦╔═╗╦  ╦╔═╗╦═╗       
║║║╠═╣║ ║ ║╣   ╠═╣║ ║╚╗╔╝║╣ ╠╦╝       
╚╩╝╩ ╩╩ ╩ ╚═╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═       
╔╗ ╔═╗  ╔╦╗╔═╗═╗ ╦╔╦╗                 
╠╩╗║ ╦   ║ ║╣ ╔╩╦╝ ║                  
╚═╝╚═╝   ╩ ╚═╝╩ ╚═ ╩                  
*/

.orangeButtonBGTextWhiteHover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.orangeButtonBGTextWhiteHover:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.orangeButtonBGTextWhiteHover:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important;
  box-shadow: 0 0 0 2px var(--secondary-color) !important;
}

/* 
 /$$      /$$ /$$   /$$ /$$$$$$ /$$$$$$$$ /$$$$$$$$
| $$  /$ | $$| $$  | $$|_  $$_/|__  $$__/| $$_____/
| $$ /$$$| $$| $$  | $$  | $$     | $$   | $$      
| $$/$$ $$ $$| $$$$$$$$  | $$     | $$   | $$$$$   
| $$$$_  $$$$| $$__  $$  | $$     | $$   | $$__/   
| $$$/ \  $$$| $$  | $$  | $$     | $$   | $$      
| $$/   \  $$| $$  | $$ /$$$$$$   | $$   | $$$$$$$$
|__/     \__/|__/  |__/|______/   |__/   |________/

*/

.whiteButton {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--primary-color) !important;
}

.whiteButton:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.whiteButton:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

/* 
╦ ╦╦ ╦╦╔╦╗╔═╗  ╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔
║║║╠═╣║ ║ ║╣   ╠╩╗║ ║ ║  ║ ║ ║║║║
╚╩╝╩ ╩╩ ╩ ╚═╝  ╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝
╦ ╦╦ ╦╦╔╦╗╔═╗  ╔╗ ╔═╗╦═╗╔╦╗╔═╗╦═╗
║║║╠═╣║ ║ ║╣   ╠╩╗║ ║╠╦╝ ║║║╣ ╠╦╝
╚╩╝╩ ╩╩ ╩ ╚═╝  ╚═╝╚═╝╩╚══╩╝╚═╝╩╚═
*/

.whiteButtonWhiteBorder {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.whiteButtonWhiteBorder:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.whiteButtonWhiteBorder:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
  box-shadow: 0 0 0 2px var(--tertiary-color) !important;
}

/* 
 /$$$$$$$$ /$$$$$$$   /$$$$$$  /$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$  /$$$$$$$  /$$$$$$$$ /$$   /$$ /$$$$$$$$
|__  $$__/| $$__  $$ /$$__  $$| $$$ | $$ /$$__  $$| $$__  $$ /$$__  $$| $$__  $$| $$_____/| $$$ | $$|__  $$__/
   | $$   | $$  \ $$| $$  \ $$| $$$$| $$| $$  \__/| $$  \ $$| $$  \ $$| $$  \ $$| $$      | $$$$| $$   | $$   
   | $$   | $$$$$$$/| $$$$$$$$| $$ $$ $$|  $$$$$$ | $$$$$$$/| $$$$$$$$| $$$$$$$/| $$$$$   | $$ $$ $$   | $$   
   | $$   | $$__  $$| $$__  $$| $$  $$$$ \____  $$| $$____/ | $$__  $$| $$__  $$| $$__/   | $$  $$$$   | $$   
   | $$   | $$  \ $$| $$  | $$| $$\  $$$ /$$  \ $$| $$      | $$  | $$| $$  \ $$| $$      | $$\  $$$   | $$   
   | $$   | $$  | $$| $$  | $$| $$ \  $$|  $$$$$$/| $$      | $$  | $$| $$  | $$| $$$$$$$$| $$ \  $$   | $$   
   |__/   |__/  |__/|__/  |__/|__/  \__/ \______/ |__/      |__/  |__/|__/  |__/|________/|__/  \__/   |__/   
                                                                                                              
*/

/* 
╦ ╦╦ ╦╦╔╦╗╔═╗  ╔═╗╦ ╦╔╦╗╦  ╦╔╗╔╔═╗  
║║║╠═╣║ ║ ║╣   ║ ║║ ║ ║ ║  ║║║║║╣   
╚╩╝╩ ╩╩ ╩ ╚═╝  ╚═╝╚═╝ ╩ ╩═╝╩╝╚╝╚═╝  
╦ ╦╦ ╦╦╔╦╗╔═╗  ╦ ╦╔═╗╦  ╦╔═╗╦═╗
║║║╠═╣║ ║ ║╣   ╠═╣║ ║╚╗╔╝║╣ ╠╦╝
╚╩╝╩ ╩╩ ╩ ╚═╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═        
*/

.transparentButtonWhiteOutline {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: transparent !important;
  border-color: var(--tertiary-color) !important;
}

.transparentButtonWhiteOutline:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.transparentButtonWhiteOutline:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

/* 
╦ ╦╦ ╦╦╔╦╗╔═╗  ╔═╗╦ ╦╔╦╗╦  ╦╔╗╔╔═╗
║║║╠═╣║ ║ ║╣   ║ ║║ ║ ║ ║  ║║║║║╣ 
╚╩╝╩ ╩╩ ╩ ╚═╝  ╚═╝╚═╝ ╩ ╩═╝╩╝╚╝╚═╝
╔═╗╦═╗╔═╗╔═╗╔╗╔  ╦ ╦╔═╗╦  ╦╔═╗╦═╗ 
║ ╦╠╦╝║╣ ║╣ ║║║  ╠═╣║ ║╚╗╔╝║╣ ╠╦╝ 
╚═╝╩╚═╚═╝╚═╝╝╚╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═ 
*/

.transparentButtonWhiteOutlineGreenHover {
  color: var(--tertiary-color) !important;
  font-weight: bold !important;
  background-color: transparent !important;
  border-color: var(--tertiary-color) !important;
}

.transparentButtonWhiteOutlineGreenHover:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
}

.transparentButtonWhiteOutlineGreenHover:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--tertiary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

/* 
╔═╗╦═╗╔═╗╔═╗╔╗╔  ╔═╗╦ ╦╔╦╗╦  ╦╔╗╔╔═╗
║ ╦╠╦╝║╣ ║╣ ║║║  ║ ║║ ║ ║ ║  ║║║║║╣ 
╚═╝╩╚═╚═╝╚═╝╝╚╝  ╚═╝╚═╝ ╩ ╩═╝╩╝╚╝╚═╝
╔═╗╦═╗╔═╗╔═╗╔╗╔  ╦ ╦╔═╗╦  ╦╔═╗╦═╗   
║ ╦╠╦╝║╣ ║╣ ║║║  ╠═╣║ ║╚╗╔╝║╣ ╠╦╝   
╚═╝╩╚═╚═╝╚═╝╝╚╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═   
*/

.transparentButtonGreenOutline {
  color: var(--primary-color) !important;
  font-weight: bold !important;
  background-color: transparent !important;
  border-color: var(--primary-color) !important;
}

.transparentButtonGreenOutline:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.transparentButtonGreenOutline:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--tertiary-color) !important;
}

/* 
╔═╗╦═╗╔═╗╔═╗╔╗╔  ╔═╗╦ ╦╔╦╗╦  ╦╔╗╔╔═╗
║ ╦╠╦╝║╣ ║╣ ║║║  ║ ║║ ║ ║ ║  ║║║║║╣ 
╚═╝╩╚═╚═╝╚═╝╝╚╝  ╚═╝╚═╝ ╩ ╩═╝╩╝╚╝╚═╝
╦ ╦╦ ╦╦╔╦╗╔═╗  ╦ ╦╔═╗╦  ╦╔═╗╦═╗     
║║║╠═╣║ ║ ║╣   ╠═╣║ ║╚╗╔╝║╣ ╠╦╝     
╚╩╝╩ ╩╩ ╩ ╚═╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═     
*/

.transparentButtonGreenOutlineWhiteHover {
  color: var(--primary-color) !important;
  font-weight: bold !important;
  background-color: transparent !important;
  border-color: var(--primary-color) !important;
}

.transparentButtonGreenOutlineWhiteHover:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--primary-color) !important;
}

.transparentButtonGreenOutlineWhiteHover:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

/* 
╔╗ ╔═╗  ╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔     
╠╩╗║ ╦  ╠╩╗║ ║ ║  ║ ║ ║║║║     
╚═╝╚═╝  ╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝     
╦ ╦╦ ╦╦╔╦╗╔═╗  ╦ ╦╔═╗╦  ╦╔═╗╦═╗
║║║╠═╣║ ║ ║╣   ╠═╣║ ║╚╗╔╝║╣ ╠╦╝
╚╩╝╩ ╩╩ ╩ ╚═╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═
*/

.darkButtonOutline {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: transparent !important;
  border-color: var(--bg-color) !important;
}

.darkButtonOutline:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--bg-color) !important;
}

.darkButtonOutline:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--tertiary-color) !important;
  border-color: var(--bg-color) !important;
  box-shadow: 0 0 0 2px var(--tertiary-color) !important;
}

/* 
╔╦╗╔═╗╦═╗╦╔═  ╔╗ ╔═╗╦═╗╔╦╗╔═╗╦═╗ 
 ║║╠═╣╠╦╝╠╩╗  ╠╩╗║ ║╠╦╝ ║║║╣ ╠╦╝ 
═╩╝╩ ╩╩╚═╩ ╩  ╚═╝╚═╝╩╚══╩╝╚═╝╩╚═ 
╔═╗╦═╗╔═╗╔═╗╔╗╔  ╦ ╦╔═╗╦  ╦╔═╗╦═╗
║ ╦╠╦╝║╣ ║╣ ║║║  ╠═╣║ ║╚╗╔╝║╣ ╠╦╝
╚═╝╩╚═╚═╝╚═╝╝╚╝  ╩ ╩╚═╝ ╚╝ ╚═╝╩╚═
*/

.transparentButtonBGOutline {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: transparent !important;
  border-color: var(--bg-color) !important;
}

.transparentButtonBGOutline:hover {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.transparentButtonBGOutline:focus {
  color: var(--bg-color) !important;
  font-weight: bold !important;
  background-color: var(--primary-color) !important;
  border-color: var(--bg-color) !important;
  box-shadow: 0 0 0 2px var(--tertiary-color) !important;
}

/* 
╦  ╦╦╔╦╗╔═╗╔═╗  ╔╗ ╔═╗╔═╗╦╔═╔═╗╦═╗╔═╗╦ ╦╔╗╔╔╦╗
╚╗╔╝║ ║║║╣ ║ ║  ╠╩╗╠═╣║  ╠╩╗║ ╦╠╦╝║ ║║ ║║║║ ║║
 ╚╝ ╩═╩╝╚═╝╚═╝  ╚═╝╩ ╩╚═╝╩ ╩╚═╝╩╚═╚═╝╚═╝╝╚╝═╩╝
*/

.videoBGSection {
  height: 668px !important;
  position: sticky !important;
  background-color: var(--bg-color);
}

.videoBG {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  opacity: 0.4 !important;
  height: 768px !important;
  z-index: -1;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 10%,
    transparent 100%
  );
}

/* 
╔╗╔╔═╗╦  ╦╔╗ ╔═╗╦═╗
║║║╠═╣╚╗╔╝╠╩╗╠═╣╠╦╝
╝╚╝╩ ╩ ╚╝ ╚═╝╩ ╩╩╚═
NAVBAR
*/

.navigationTopBorder {
  /* border-top-color: var(--primary-color) !important;
  border-top-width: 1px !important;
  border-top-style: solid; */
}

.navbarGradient {
  background: linear-gradient(
    180deg,
    rgba(56, 56, 56, 0.5) 0%,
    rgba(56, 56, 56, 0.25) 60%,
    rgba(56, 56, 56, 0) 100%
  ) !important;
}

.navbar-toggler-icon {
  background-image: url("./assets/list.png") !important;
}

.navbar-toggler {
  border-color: rgba(0, 0, 0, 0) !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-close:focus,
.btn-close:active {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-default {
  box-shadow: none !important;
}

/* 
╔═╗╔╗╔╦╔╦╗╔═╗╔╦╗╦╔═╗╔╗╔╔═╗
╠═╣║║║║║║║╠═╣ ║ ║║ ║║║║╚═╗
╩ ╩╝╚╝╩╩ ╩╩ ╩ ╩ ╩╚═╝╝╚╝╚═╝
*/

.animateEntry1 {
  animation: appearAni 0.5s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.animateEntry2 {
  animation: appearAni 0.5s ease-out forwards;
  animation-delay: 0.7s;
  opacity: 0;
}

@keyframes appearAni {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 
╔═╗╔╦╗╦ ╦╔═╗╦═╗  ╦═╗╔═╗╔═╗╔╦╗  ╔═╗╔═╗╔╦╗╔╦╗╦╔╗╔╔═╗╔═╗
║ ║ ║ ╠═╣║╣ ╠╦╝  ╠╦╝║ ║║ ║ ║   ╚═╗║╣  ║  ║ ║║║║║ ╦╚═╗
╚═╝ ╩ ╩ ╩╚═╝╩╚═  ╩╚═╚═╝╚═╝ ╩   ╚═╝╚═╝ ╩  ╩ ╩╝╚╝╚═╝╚═╝
OTHER ROOT SETTINGS
*/

a {
  color: #383838 !important;
  text-decoration: none !important;
}

a:hover {
  color: var(--primary-color) !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.opacity50 {
  opacity: 0.5 !important;
}

.noPaddingMobile {
  padding: 0px !important;
}

/* 
╔═╗╔═╗╔╦╗╔╦╗╦╔╗╔╔═╗  ╔═╗╔═╗╦═╗╔═╗
╠═╝╠═╣ ║║ ║║║║║║║ ╦  ╔═╝║╣ ╠╦╝║ ║
╩  ╩ ╩═╩╝═╩╝╩╝╚╝╚═╝  ╚═╝╚═╝╩╚═╚═╝
 */

.paddingLeft0 {
  padding-left: 0px !important;
}

.paddingLeft12 {
  padding-left: 12px !important;
}

.paddingLeft24 {
  padding-left: 24px !important;
}

.paddingLeft36 {
  padding-left: 36px !important;
}

.paddingLeft48 {
  padding-left: 48px !important;
}

.paddingLeft60 {
  padding-left: 60px !important;
}

.paddingRight0 {
  padding-right: 0px !important;
}

.paddingRight12 {
  padding-right: 12px !important;
}

.paddingRight24 {
  padding-right: 24px !important;
}

.paddingRight36 {
  padding-right: 36px !important;
}

.paddingRight48 {
  padding-right: 48px !important;
}

.paddingRight60 {
  padding-right: 60px !important;
}

.paddingTop0 {
  padding-top: 0px !important;
}

.paddingTop12 {
  padding-top: 12px !important;
}

.paddingTop24 {
  padding-top: 24px !important;
}

.paddingTop36 {
  padding-top: 36px !important;
}

.paddingTop48 {
  padding-top: 48px !important;
}

.paddingTop60 {
  padding-top: 60px !important;
}

.paddingBottom0 {
  padding-bottom: 0px !important;
}

.paddingBottom12 {
  padding-bottom: 12px !important;
}

.paddingBottom24 {
  padding-bottom: 24px !important;
}

.paddingBottom36 {
  padding-bottom: 36px !important;
}

.paddingBottom48 {
  padding-bottom: 48px !important;
}

.paddingBottom60 {
  padding-bottom: 60px !important;
}

/* 
╔╦╗╔═╗╦═╗╔═╗╦╔╗╔
║║║╠═╣╠╦╝║ ╦║║║║
╩ ╩╩ ╩╩╚═╚═╝╩╝╚╝
*/

.marginLeft0 {
  margin-left: 0px !important;
}

.marginLeft1 {
  margin-left: 1px !important;
}

.marginLeft2 {
  margin-left: 2px !important;
}

.marginLeft3 {
  margin-left: 3px !important;
}
.marginLeft4 {
  margin-left: 4px !important;
}

.marginLeft5 {
  margin-left: 5px !important;
}

.marginLeft6 {
  margin-left: 6px !important;
}

.marginLeft12 {
  margin-left: 12px !important;
}

.marginLeft24 {
  margin-left: 24px !important;
}

.marginLeft36 {
  margin-left: 36px !important;
}

.marginLeft48 {
  margin-left: 48px !important;
}

.marginLeft60 {
  margin-left: 60px !important;
}

.marginRight0 {
  margin-right: 0px !important;
}

.marginRight12 {
  margin-right: 12px !important;
}

.marginRight24 {
  margin-right: 24px !important;
}

.marginRight36 {
  margin-right: 36px !important;
}

.marginRight48 {
  margin-right: 48px !important;
}

.marginRight60 {
  margin-right: 60px !important;
}

.marginTop0 {
  margin-top: 0px !important;
}
.marginTop6 {
  margin-top: 6px !important;
}
.marginTop12 {
  margin-top: 12px !important;
}

.marginTop24 {
  margin-top: 24px !important;
}

.marginTop36 {
  margin-top: 36px !important;
}

.marginTop48 {
  margin-top: 48px !important;
}

.marginTop60 {
  margin-top: 60px !important;
}

.marginBottom0 {
  margin-bottom: 0px !important;
}

.marginBottom6 {
  margin-bottom: 6px !important;
}

.marginBottom12 {
  margin-bottom: 12px !important;
}

.marginBottom24 {
  margin-bottom: 24px !important;
}

.marginBottom36 {
  margin-bottom: 36px !important;
}

.marginBottom48 {
  margin-bottom: 48px !important;
}

.marginBottom60 {
  margin-bottom: 60px !important;
}

/*
 ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗███████╗    
██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝    
██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║   ███████╗    
██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║   ╚════██║    
╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║   ███████║    
 ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝    
                                                                                              
COMPONENTS
*/
.cardStyleCustomizable {
  /* background-color: #383838 !important; */
  /* border: 1px solid rgb(158 204 74) !important; */
  border-width: 1px !important;
  border-style: solid;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 12px !important;
  margin-right: 24px !important;
  width: 280px !important;
  border-radius: 10px !important;
  margin-bottom: 24px !important;
}

/* 

╔═╗╔╦╗╦ ╦╔═╗╦═╗   ╦╦ ╦╔╗╔╦╔═  
║ ║ ║ ╠═╣║╣ ╠╦╝   ║║ ║║║║╠╩╗  
╚═╝ ╩ ╩ ╩╚═╝╩╚═  ╚╝╚═╝╝╚╝╩ ╩  

*/

/* MARGIN  */

.marginLeft-18 {
  margin-left: -18px;
}

.marginLeft-24 {
  margin-left: -24px;
}
.paddingBottom10vh {
  padding-bottom: 10vh !important;
}

.paddingBottom15vh {
  padding-bottom: 15vh !important;
}

.marginTop10vh {
  margin-top: 10vh !important;
}

.paddingBottom10vh {
  padding-bottom: 10vh !important;
}

.marginTop5vh {
  margin-top: 5vh !important;
}

.paddingLeftRight60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.paddingLeftRight20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.marginTop18 {
  margin-top: 18px !important;
}

.marginTop32 {
  margin-top: 32px !important;
}

.marginTop72 {
  margin-top: 72px !important;
}

.marginLeft-18 {
  margin-left: -18px !important;
}

.marginLeft18 {
  margin-left: 18px !important;
}

.marginTop-6 {
  margin-top: -6px !important;
}
.marginBottom-6 {
  margin-bottom: -6px !important;
}

.marginBottom6 {
  margin-bottom: 6px !important;
}

.marginBottom8 {
  margin-bottom: 8px !important;
}
.marginLeft50vh {
  margin-left: 50vw;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

.nav-link {
  color: var(--primary-color) !important;
}

.rightAligned {
  text-align: end;
}

.fullWidth {
  width: 100% !important;
}

.fillImage {
  object-fit: cover;
  /* width: 300px; */
  height: 300px;
  border-radius: 20px;
}

.whiteBorder1px {
  border: 1px solid rgb(255, 255, 255, 0.5) !important;
}

.zIndex1 {
  z-index: 1;
}

.zIndex2 {
  z-index: 2;
}

.zIndex100 {
  z-index: 100;
}
